<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    class="canvas"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          contain
        >
          <v-icon
            large
            color="secondary"
          >
            mdi-account-circle
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      class="no-padding"
      dense
      rounded
    >
      <template
        v-for="(item, i) in menuItems"
      >
        <v-list-item
          :key="i"
          :to="item.to"
          link
          :active-class="`primary white--text`"
        >
          <v-list-item-icon>
            <v-icon
              color="secondary"
              v-text="item.icon"
            />
          </v-list-item-icon>
          <v-list-item-title
            color="secondary"
            v-text="item.title"
          />
        </v-list-item>
      </template>
    </v-list>

    <!-- npm -->
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState } from 'vuex'
  import { checkPermission } from '@/router/permissions'
  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-storefront',
          title: 'stores',
          to: '/pages/stores',
        },
        {
          icon: 'mdi-home-city',
          title: 'regions',
          to: '/pages/regions',
        },
        {
          icon: 'mdi-file-presentation-box',
          title: 'orders',
          to: '/pages/orders',
        },
        {
          icon: 'mdi-truck-delivery',
          title: 'deliveries',
          to: '/pages/deliveries',
        },
        {
          icon: 'mdi-monitor',
          title: 'storemonitor',
          to: '/pages/storemonitor',
        },
        {
          icon: 'mdi-account-group',
          title: 'staff',
          to: '/pages/staff',
        },
        {
          title: 'customers',
          icon: 'mdi-account-multiple-outline',
          to: '/pages/customers',
        },
        {
          title: 'users',
          icon: 'mdi-account-multiple',
          to: '/pages/users',
        },
        // {
        //   icon: 'mdi-warehouse',
        //   title: 'warehouses',
        //   to: '/pages/warehouses',
        // },
        // {
        //   icon: 'mdi-handshake',
        //   title: 'dealers',
        //   to: '/pages/dealers',
        // },
        {
          title: 'reports',
          icon: 'mdi-text-box',
          to: '/pages/reports',
        },
        {
          title: 'billing',
          icon: 'mdi-cash-100',
          to: '/pages/billing',
        },
        {
          title: 'offers',
          icon: 'mdi-tag',
          to: '/pages/offers',
        },
        {
          title: 'settings',
          icon: 'mdi-cog',
          to: '/pages/settings',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
      menuItems () {
        const role = this.$store.getters['authentication/getRole']
        return this.items.filter(item => checkPermission(role, item.title, 'list')).map(this.mapItem)
      },
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    mounted () {
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style scoped>
</style>
